<template>
  <div class="privacy-accept">
    <Politic />
    <input
      type="checkbox"
      name="privacyCheck"
      value="male"
      class="mycheck circle"
      checked
    />
    <div class="warn"></div>
    <span class="text">
      {{ $locale_local["signup"]["data_privacy_message"] }}
    </span>
  </div>
</template>
<script>
import Politic from "./Politics";

export default {
  components: { Politic },
};
</script>
<style lang="scss" scoped>
.privacy-accept {
  text-align: center;
  position: relative;
  z-index: 1;
  .mycheck {
    float: left;
    margin: 0 $mpadding/1.5 0 0;
  }
  .mycheck ~ .warn {
    position: absolute;
    background-color: rgba($primary_color, 0.8);
    top: -$mpadding;
    left: -$mpadding;
    width: calc(100% + #{$mpadding} * 2);
    height: calc(100% + #{$mpadding} * 2);
    z-index: -1;
  }
  .mycheck ~ .text {
    color: #fff;
  }
  .mycheck:checked ~ .warn,
  .mycheck:checked ~ .text {
    background-color: #fff;
    color: inherit;
    border-radius: 12px;
  }
}
</style>
