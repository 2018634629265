<template>
  <div>
    <div class="parrafo" v-if="mylang === 'es'">
      <h1 class="title">POLITICA DE TRATAMIENTO DE DATOS PERSONALES</h1>
      <p>
        {{ $locale_local["words"]["title"] }}, acogiendo y dando cumplimiento a lo dispuesto en la Ley 1581 de 2012 y el Decreto Reglamentario 1377 de
        2013 y lo consignado en el artículo 15 de nuestra Constitución Política, adopta y aplica la presente Política para el tratamiento de los datos
        personales garantizando la intimidad, derechos a la privacidad, y el buen nombre de las personas, durante el proceso del tratamiento de datos
        personales, en todas las actividades, las cuales tendrán los principios de confidencialidad, seguridad, legalidad, acceso, libertad y
        transparencia.
      </p>
      <p>
        {{ $locale_local["words"]["title"] }} se compromete a no revelar la información que se digita, adjunta o transfiere a nuestra empresa, de
        acuerdo con las normas de la Ley 527 que reglamenta el Comercio Electrónico en Colombia y la Ley 1581 de 2012 sobre el uso de datos
        confidenciales. Con la presente Política de Tratamiento y Protección de Datos Personales, se suple dejando sin efecto acuerdos y políticas
        expedidas con anterioridad.
      </p>
      <p>
        Para dar cumplimiento a las políticas de protección de datos y a las obligaciones de la Ley 1581 de 2012, sus Decretos Reglamentarios y las
        demás normas que la complementen, adicionen o modifiquen, tiene en cuenta lo siguiente para el manejo de información y datos personales:
      </p>
      <p>
        La información personal es uno de los activos más importantes, por lo tanto, el tratamiento de esta información se realiza con sumo cuidado y
        atendiendo lo establecido por la ley, garantizando a las personas el pleno ejercicio y respeto por su derecho del Hábeas Data.
      </p>
      <p>
        La información que se encuentra en la Base de Datos propia ha sido obtenida en desarrollo de la actividad de
        {{ $locale_local["words"]["title"] }}, su recopilación se ha hecho y se hará siempre atendiendo a los criterios y normatividad legal.
      </p>
      <h4>ALCANCE DE LA POLÍTICA DE PROTECCIÓN DE DATOS:</h4>
      <p>
        La Política de Protección de Datos Personales de {{ $locale_local["words"]["title"] }} se aplicará a todas las Bases de Datos y/o archivos que
        contengan Datos Personales, que para {{ $locale_local["words"]["title"] }} sea objeto de Tratamiento como responsable y/o encargado del
        tratamiento de Datos Personales.
      </p>
      <p>
        El Tratamiento de los Datos Personales se deberá hacer en los términos, condiciones y alcances de la autorización del Titular y/o en
        aplicación de las normas especiales cuando proceda alguna excepción legal para hacerlo. Cualquier tipo de solicitud, producto del ejercicio de
        los deberes y derechos consagrados en la política, podrá dirigirse la Calle 32F # 78 - 78 de la ciudad de Medellín, Colombia, con teléfono de
        contacto 3187081351.
      </p>
      <h4>RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES</h4>
      <p>{{ $locale_local["words"]["title"] }}, será el responsable del tratamiento de los datos personales y las bases de datos.</p>
      <h4>FINALIDAD DE LA RECOLECCIÓN Y TRATAMIENTO DE DATOS PERSONALES</h4>
      <p>La finalidad de la Política de Protección de Datos Personales busca:</p>
      <dl>
        <li class="order">Instrumentar los procedimientos de recolección y tratamiento de datos personales a las disposiciones de la ley.</li>
        <li class="order">
          Generar un esquema organizado para salvaguardar los datos privados, semiprivados, públicos y sensibles de sus titulares.
        </li>
      </dl>
      <p>La finalidad de {{ $locale_local["words"]["title"] }} con respecto a la recolección y tratamiento de Datos Personales frente a:</p>

      <p>
        <em>ASPIRANTES, ALIADOS O USUARIOS:</em>
        Busca llevar a sus Usuarios, Aspirantes o postulantes información y beneficios de pertenecer a {{ $locale_local["words"]["title"] }}, por
        consiguiente, el Aspirante acepta que {{ $locale_local["words"]["title"] }} lo contacte por diferentes canales como teléfono fijo, teléfono
        celular, mensajes a su celular, correo electrónico y redes sociales, para ofrecerle información y beneficios.
      </p>
      <p>
        <em>EMPLEADOS:</em>
        Esta finalidad incluye específicamente los pagos de salarios y obligaciones como empleador de las afiliaciones y aportes a seguridad social y
        cajas de compensación, tanto de los empleados como de sus familiares y control de novedades laborales como permisos, incapacidades, control de
        acceso y horario de trabajo del empleado.
      </p>
      <p>
        <em>PROVEEDORES:</em>
        Contacto permanente para solicitud de cotizaciones y gestión de las relaciones comerciales que surjan, con el objeto de adquirir sus productos
        o servicios como insumos para el funcionamiento según la razón social de {{ $locale_local["words"]["title"] }}
      </p>
      <p>
        <em>VISITANTES: {{ $locale_local["words"]["title"] }}</em>
        recibe en sus instalaciones con poca frecuencia y cantidades mínimas visitantes, teniendo en cuenta que toda la gestión de sus actividades se
        realiza mediante el comercio electrónico. La información que suministran los visitantes a manera de control de acceso, es registrada por el
        personal administrativo buscando obtener control frente a posibles incidentes de seguridad e identificación de las personas que ingresan a las
        instalaciones de {{ $locale_local["words"]["title"] }}
      </p>
      <h4>DEFINICIONES:</h4>
      <dl>
        <li class="order">
          Autorización: Consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de datos personales.
        </li>
        <li class="order">
          Aviso de privacidad: Comunicación verbal o escrita generada por el Responsable dirigida al Titular para el tratamiento de sus datos
          personales, mediante la cual se le informa acerca de la existencia de las políticas de Tratamiento de información que le serán aplicables,
          la forma de acceder a las mismas y las finalidades del Tratamiento que se pretende dar a los datos personales.
        </li>
        <li class="order">Base de Datos: Conjunto organizado de datos personales que sea objeto de Tratamiento.</li>
        <li class="order">
          Dato personal: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.
        </li>
        <li class="order">Dato privado: Es el dato que por su naturaleza íntima o reservada sólo es relevante para el titular.</li>
        <li class="order">
          Datos sensibles: Se entiende por datos sensibles aquellos que afectan la intimidad del Titular o cuyo uso indebido puede generar su
          discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o
          filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido
          político o que garanticen los derechos y garantías de partidos políticos de oposición, así como los datos relativos a la salud, a la vida
          sexual y los datos biométricos.
        </li>
        <li class="order">
          Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de
          datos personales por cuenta del Responsable del Tratamiento.
        </li>
        <li class="order">
          Responsable del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de
          datos y/o el Tratamiento de los datos.
        </li>
        <li class="order">Titular: Persona natural cuyos datos personales sean objeto de Tratamiento.</li>
        <li class="order">
          Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso,
          circulación o supresión de los mismos.
        </li>
        <li class="order">
          Términos y Condiciones: marco general en el cual se establecen las condiciones para los participantes de actividades promocionales o afines.
        </li>
      </dl>
      <h4>AMBITO DE APLICACIÓN:</h4>
      <p>
        Las políticas de tratamiento de Datos Personales deben ser conocidas y aplicadas por todos los funcionarios, empleados, dependencias, clientes
        y proveedores de {{ $locale_local["words"]["title"] }}
      </p>
      <h4>BASE DE DATOS DE {{ $locale_local["words"]["title"] }}:</h4>
      <p>
        Las políticas y procedimientos aplican a la Base de Datos que maneja {{ $locale_local["words"]["title"] }} y serán registradas de conformidad
        con lo dispuesto en la ley.
      </p>
      <h4>CONSULTA DE LA POLÍTICA:</h4>
      <p>
        La Política de Protección de Datos Personales debe estar anunciada, presentada y dispuesta en el sitio oficial de
        {{ $locale_local["words"]["title"] }}
        "models1a.com", presentando fácil consulta y acceso por parte del público en general.
      </p>
      <h4>DERECHOS DE LOS TITULARES DE LOS DATOS PERSONALES:</h4>
      <p>
        Los titulares de los datos personales, son las personas naturales cuyos datos personales sean objeto de Tratamiento por parte de
        {{ $locale_local["words"]["title"] }}
      </p>
      <p>
        De acuerdo con lo contemplado por la normatividad vigente aplicable en materia de protección de datos, los siguientes son los derechos de los
        titulares de los datos personales, los cuales los pueden ejercer en cualquier momento:
      </p>
      <dl>
        <li class="order">
          Acceder, conocer, actualizar y rectificar los datos personales sobre los cuales {{ $locale_local["words"]["title"] }} está realizando el
          Tratamiento. De igual manera, el titular puede solicitar en cualquier momento, que sus datos sean actualizados o rectificados al encontrar
          que sus datos se encuentran parciales, incorrectos, inexactos, incompletos, fraccionados, induzcan a error, o aquellos cuyo tratamiento no
          haya sido autorizado o sea expresamente prohibido.
        </li>
        <li class="order">Ser informado por {{ $locale_local["words"]["title"] }}, respecto del uso que le ha dado a sus datos personales.</li>
        <li class="order">
          Revocar la autorización y/o solicitar la supresión del dato cuando en el tratamiento no se respeten los principios, derechos, y las
          garantías constitucionales y legales.
        </li>
        <li class="order">
          Solicitar prueba de la autorización otorgada a {{ $locale_local["words"]["title"] }} para el tratamiento de datos, mediante cualquier medio
          válido, salvo en los casos en que no es necesaria la autorización.
        </li>
        <li class="order">
          Presentar ante la Superintendencia de Industria y Comercio, quejas por infracciones a lo dispuesto en la ley 1581 de 2012 y las demás normas
          que la modifiquen, adicionen o complementen, previo trámite de consulta o requerimiento ante {{ $locale_local["words"]["title"] }}
        </li>
        <li class="order">Acceder y consultar de forma gratuita a sus datos personales objeto de tratamiento.</li>
      </dl>
      <h4>ACTUALIZAR, CORREGIR, RECTIFICAR O SUPRIMIR LOS DATOS DEL TITULAR:</h4>
      <p>
        El titular de los datos personales puede solicitar a {{ $locale_local["words"]["title"] }} por medio del correo electrónico
        habeasdata@omgwgroup.com de solicitudes, que sus datos personales tratados sean actualizados, corregidos, rectificados o suprimidos, si así lo
        desea o si considera que existe incumplimiento a cualquiera de los deberes en el Régimen General de Protección de Datos Personales o en la
        presente Política. Para poder efectuar la actualización, corrección, rectificación o supresión, el Titular debe tramitar la solicitud dirigida
        al responsable o encargado del tratamiento indicando:
      </p>
      <dl>
        <li class="order">Nombre completo e identificación del titular de los datos.</li>
        <li class="order">Descripción detallada de los hechos que dan lugar de la solicitud.</li>
        <li class="order">Datos de ubicación del titular como la dirección, departamento, ciudad y teléfono de contacto.</li>
        <li class="order">Descripción del procedimiento que desea realizar (actualización, corrección, rectificación o supresión).</li>
        <li class="order">Y si considera necesario adjuntar documentos que soporten la solicitud (este punto es opcional).</li>
      </dl>
      <p>
        Recibida la solicitud del Titular de los datos personales por medio del correo habeasdata@omgwgroup.com de solicitudes, con los puntos
        correctamente diligenciados para tramitarla de manera al responsable o encargado del tratamiento, manteniendo abierto el caso en un término no
        mayor a cinco (5) días hábiles desde la fecha su recepción, tiempo en el que se debe dar respuesta y solución a la solicitud. Así mismo, se
        tendrán dos (2) días hábiles para dar traslado a la persona encargada de darle solución (competente); si quien la recibe no está legitimado
        para responderla, tendrá quince (15) días hábiles contados a partir del día de recepción de la solicitud para atenderla. De no cumplir el
        Titular con los puntos correctamente diligenciados para tramitar la solicitud dirigida al responsable o encargado del tratamiento, se le
        solicitará al Titular (interesado) la corrección de los requisitos dentro de los siguientes cinco (5) días a la fecha de recepción. Al
        transcurrir quince (15) contados a partir de la fecha, desde que se le solicita al Titular el cumplimiento de los requisitos establecidos, y
        no obtener respuesta alguna o insistir en el procedimiento equivocado, se asimilará como desistimiento de la solicitud. Si por parte de
        {{ $locale_local["words"]["title"] }}, no ha sido posible dar respuesta a la solicitud dentro del término señalado, se le informará al Titular
        (interesado), listando y detallando los motivos por los cuales no fue posible atender su solicitud y notificándole la fecha en la que será
        resuelta.
      </p>
      <h4>REVOCAR LA AUTORIZACIÓN PARA TRATAR LOS DATOS PERSONALES:</h4>
      <p>
        En cualquier momento el titular de los Datos Personales puede revocar la autorización para el tratamiento de sus Datos Personales
        suministrados a {{ $locale_local["words"]["title"] }}; para ello debe gestionar la solicitud dirigida al responsable del tratamiento o al
        encargado del mismo, detallando el objeto de su solicitud. El Titular de los Datos Personales para poder gestionar la revocación ante
        {{ $locale_local["words"]["title"] }}, debe llevar a cabo los mismos pasos y requisitos consagrados en el procedimiento para actualización,
        corrección, rectificación o supresión de los datos personales.
      </p>
      <h4>CONSULTAS SOBRE EL TRATAMIENTO DE DATOS POR SUS TITULARES:</h4>
      <p>
        Los Titulares podrán consultar su información personal que tenga {{ $locale_local["words"]["title"] }}, quien a su vez estará dispuesto a
        suministrar toda la información que esté vinculada con la identificación del Titular.
      </p>
      <p>Con respecto a la atención de solicitudes de consulta de Datos Personales, {{ $locale_local["words"]["title"] }} garantiza:</p>
      <dl>
        <li>Habilitar medios de comunicación electrónica u otros que considere pertinentes.</li>
        <li>Ofrecer y notificar los formularios, sistemas y otros métodos de contacto.</li>
        <li>
          Atender en un término máximo de quince (15) días hábiles los casos de atención de las solicitudes, los cuales se contarán a partir de la
          fecha de solicitud. De no ser posible atenderla dentro del tiempo definido, se informará al interesado antes de su vencimiento, notificando
          los motivos del retraso y la nueva fecha en que se dará solución. Este nuevo plazo no podrá superar los cinco (5) días hábiles adicionales
          al plazo inicial.
        </li>
      </dl>
      <h4>CANDIDATOS:</h4>
      <p>
        {{ $locale_local["words"]["title"] }} no es una empresa dedicada a buscar, ubicar, canalizar y contactar posibles candidatos para una vacante,
        sin embargo, dispone de canales electrónicos para que las personas que deseen hacer parte del equipo de trabajo, vincularse como aliados o
        contratistas adjunten su información.
      </p>
      <p>
        {{ $locale_local["words"]["title"] }} deja a disposición del candidato o interesado, en cualquier momento la posibilidad de revocar la
        autorización para el tratamiento de sus datos personales suministrados de manera totalmente voluntaria, gestionando la solicitud dirigida al
        responsable del tratamiento o al encargado del mismo y detallando el objeto de su solicitud.
      </p>
      <p>
        Si un candidato o interesado envía su información directamente a {{ $locale_local["words"]["title"] }} por algún medio de contacto, y no se ha
        adicionado como una preselección en algún proceso, se procede de inmediato a la eliminación de la misma de todos los registros de
        {{ $locale_local["words"]["title"] }}, garantizando la protección de sus datos personales y evitando que sean utilizados para actividades o
        procesos de selección en los cuales el candidato no manifiesta su interés en participar.
      </p>
      <p>
        El auto candidato de los Datos Personales debe gestionar la revocación ante {{ $locale_local["words"]["title"] }}, llevando a cabo los mismos
        pasos y requisitos consagrados en el procedimiento para su actualización, corrección, rectificación o supresión de los datos personales.
      </p>
      <h4>CATEGORÍA DE LOS DATOS:</h4>
      <p>
        {{ $locale_local["words"]["title"] }} en desarrollo del principio de autonomía privada, y de acuerdo con los datos tratados, y según la
        legislación vigente, ha elaborado la siguiente clasificación de datos:
      </p>
      <dl>
        <li class="order">Dato Personal: Conjunto de información susceptible de relacionarse a una o más personas naturales.</li>
        <li class="order">
          Dato público: Es dato público todo aquel contenido en documentos públicos, relativo al estado civil de las personas, a su profesión u oficio
          y a su calidad de comerciante o de servidor público. Son datos públicos, por ejemplo, los contenidos en la cédula de ciudadanía, en
          registros públicos, en sentencias judiciales debidamente ejecutoriadas y no sometidas a reserva. Por lo anterior, será también dato público
          el que no es semiprivado, privado o sensible.
        </li>
        <li class="order">
          Dato semiprivado: Es aquel cuyo conocimiento o divulgación interesa a su titular y a un determinado grupo de personas o sector social. Por
          ejemplo, la actividad comercial o profesional.
        </li>
        <li class="order">
          Dato privado: Es aquel cuyo conocimiento o divulgación, por tener naturaleza íntima y reservada, interesa sólo a su titular.
        </li>
        <li class="order">Dato reservado: Es aquel que posee una naturaleza confidencial o un alto valor comercial por sí mismo.</li>
        <li class="order">
          Dato sensible: Es aquel que afecta la intimidad de su titular o que su uso indebido puede generar discriminación. Por ejemplo, aquellos
          relativos a la orientación sexual, la orientación política, el origen étnico o racial, las convicciones religiosas o filosóficas, la
          participación en grupos sindicales, de Derechos Humanos o sociales, entre otros.
        </li>
      </dl>
      <h4>TRATAMIENTO DE DATOS PERSONALES DE NATURALEZA SENSIBLE:</h4>
      <p>
        De acuerdo con la Ley de Protección de Datos Personales, se consideran como datos de naturaleza sensible aquellos que afectan la intimidad o
        cuyo uso indebido puede generar discriminación o señalamientos. El Tratamiento de los Datos Personales de naturaleza sensible está prohibido
        por la ley, salvo que se cuente con autorización expresa, previa e informada del Titular, entre otras excepciones consagradas en el Artículo
        6º de la Ley 1581 de 2012. Los datos de naturaleza sensible son los relacionados con:
      </p>
      <dl>
        <li class="order">Origen racial o étnico.</li>
        <li class="order">Orientación política.</li>
        <li class="order">Convicciones religiosas / filosóficas.</li>
        <li class="order">Pertenencia a sindicatos, organizaciones sociales, organizaciones de derechos humanos o partidos políticos.</li>
        <li class="order">Vida sexual.</li>
        <li class="order">Datos biométricos (como la huella dactilar, la firma y la foto).</li>
      </dl>
      <p>
        El suministro de uno o algunos de los datos personales de naturaleza sensible para aspirar a una vinculación en calidad de Aliado Comercial es
        un acto completamente autónomo por parte del candidato, en caso de no quedar seleccionado, se procede de inmediato a la eliminación de los
        datos personales de todos los registros de {{ $locale_local["words"]["title"] }}, garantizando la protección de los mismos y evitando que sean
        utilizados para actividades o procesos de selección en los cuales el candidato no manifiesta su interés en participar.
      </p>
      <h4>MARCO NORMATIVO Y LEGAL:</h4>
      <p>
        Las políticas de protección de Datos Personales de {{ $locale_local["words"]["title"] }} se rigen por las siguientes normas de manera interna
        y externa:
      </p>
      <p>
        LEY 527 DE 1999: Define y reglamenta el acceso y uso de los mensajes de datos, del comercio electrónico y de las firmas digitales, y se
        establecen las entidades de certificación y se dictan otras disposiciones. Así mismo, introduce el concepto de equivalente funcional, firma
        electrónica como mecanismos de autenticidad, disponibilidad y confidencialidad de la información.
      </p>
      <p>
        LEY 1266 DE 2008: Por la cual se dictan las disposiciones generales del Hábeas Data y se regula el manejo de la información contenida en bases
        de datos personales, en especial la financiera, crediticia, comercial, de servicios y la proveniente de terceros países y se dictan otras
        disposiciones.
      </p>
      <p>
        LEY 1273 DE 2009: Ley por medio de la cual se crea y se protege el bien jurídico de la información y los datos personales. Así mismo, se
        tipifican conductas penales como daño informático, violación de datos personales, acceso abusivo a sistema informático, interceptación de
        datos informáticos, hurto por medios informáticos, entre otras.
      </p>
      <p>LEY 1581 DE 2012: Por la cual se dictan disposiciones generales para la protección de datos personales.</p>
      <p>
        DECRETO 1377 DE 2013: Con el cual se reglamenta la Ley 1581 de 2012, sobre aspectos relacionados con la autorización del Titular de
        información para el Tratamiento de sus datos personales, las políticas de Tratamiento de los Responsables y Encargados, el ejercicio de los
        derechos de los Titulares de información, las transferencias de datos personales y la responsabilidad demostrada frente al Tratamiento de
        datos personales.
      </p>
      <p>
        DECRETO 368 DE 2014: Por el cual se reglamentan las operaciones mediante sistemas de financiación previstas en el artículo 45 de la Ley 1480
        de 2011.
      </p>
      <p>
        DECRETO 886 DE 2014: Por el cual se reglamenta el artículo 25 de la Ley 1581 de 2012, relativo al Registro Nacional de Bases de Datos
        Personales, el cual se encuentra a cargo de la Superintendencia de Industria y Comercio, y donde quienes actúen como Responsables del
        tratamiento de datos personales, deberán registrar sus Base de Datos siguiendo las instrucciones de este decreto.
      </p>
      <h4>FUNCIONES DEL RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES:</h4>
      <p>
        Dentro de las funciones del responsable del tratamiento de los datos personales, se encuentra el nombramiento de una persona al interior de la
        empresa que cumpla con las siguientes funciones:
      </p>
      <dl>
        <li class="order">
          Garantizar la elaboración, implementación y promoción de un sistema que permita administrar los riesgos del tratamiento de datos personales.
        </li>
        <li class="order">Comunicar e impulsar una cultura de protección de datos dentro de la organización.</li>
        <li class="order">
          Integrar y enlazar todas las áreas de {{ $locale_local["words"]["title"] }} para asegurar una implementación transversal de las Políticas de
          Protección de Datos Personales.
        </li>
        <li class="order">
          Verificar y auditar que las Bases de Datos de {{ $locale_local["words"]["title"] }} sean registradas en el Registro Nacional de Bases de
          Datos, y actualizar el reporte según las instrucciones de la Superintendencia de Industria y Comercio.
        </li>
        <li class="order">Programar y garantizar un entrenamiento constante de la compañía en la protección de datos personales.</li>
        <li class="order">
          Analizar y diagnosticar las responsabilidades de los cargos dentro de {{ $locale_local["words"]["title"] }}, para liderar el programa de
          capacitación en la protección de datos personales.
        </li>
        <li class="order">
          Garantizar que dentro del proceso de análisis de desempeño de los empleados, el entrenamiento y uso sobre la protección de datos personales,
          se encuentre en un nivel alto.
        </li>
        <li class="order">
          Realizar la capacitación y transmitir la responsabilidad a los nuevos empleados que por las condiciones de su trabajo, tengan acceso a las
          Bases de Datos.
        </li>
        <li class="order">
          Organizar y hacer seguimiento a la implementación de planes de auditoría interna, para verificar el cumplimiento de las políticas de
          tratamiento de datos personales.
        </li>
        <li class="order">Obtener cuando sea requerido, las declaraciones de conformidad de la Superintendencia de Industria y Comercio.</li>
      </dl>
      <h4>CONFIDENCIALIDAD Y SEGURIDAD DE LA BASE DE DATOS:</h4>
      <p>
        {{ $locale_local["words"]["title"] }} aplicará las mejores prácticas para la seguridad, discreción, protección, almacenamiento y
        confidencialidad de los Datos Personales de los titulares. Verificará cuando corresponda, la procedencia de las excepciones legales para
        entregar los datos personales a las autoridades y en los casos pertinentes.
      </p>
      <h4>GARANTÍAS DE ACCESO AL TITULAR A LOS DATOS PERSONALES:</h4>
      <p>
        {{ $locale_local["words"]["title"] }} garantiza el derecho de acceso al titular de los datos, con previa acreditación de su identidad,
        legitimidad y sin ningún costo, a sus datos personales a través de diferentes medios, principalmente electrónicos que permitan el acceso
        directo del titular a ellos. Dicho acceso deberá ofrecerse sin límite alguno y se le debe permitir al titular la posibilidad de conocerlos y
        actualizarlos en línea.
      </p>
      <h4>ACTUALIZACIÓN DE LAS BASE DE DATOS:</h4>
      <p>Actualizar la información conforme se obtengan los datos, de conformidad con lo señalado en la ley 1581 de 2012.</p>
      <h4>PRUEBA DE LA AUTORIZACIÓN DEL TITULAR:</h4>
      <p>
        Conservar la prueba de la autorización otorgada por los titulares de los datos personales para su tratamiento, utilizando mecanismos digitales
        y reglas de seguridad necesarias para mantener el registro de la forma y fecha. {{ $locale_local["words"]["title"] }} establece los
        repositorios electrónicos para salvaguardar la información.
      </p>
      <h4>INSCRIPCIÓN EN EL REGISTRO DE BASE DE DATOS DE LA SUPERINTENDENCIA DE INDUSTRIA Y COMERCIO:</h4>
      <p>
        {{ $locale_local["words"]["title"] }} cumplirá las obligaciones que la normatividad le imponga en relación con el registro e informes que debe
        entregar a las autoridades competentes. Para efectos del registro de la Base de Datos, se realizará un procedimiento teniendo en cuenta los
        siguientes parámetros:
      </p>
      <dl>
        <li class="order">Cantidad de bases de datos con información personal.</li>
        <li class="order">Cantidad de titulares por cada base de datos.</li>
        <li class="order">Información detallada de los canales o medios que se tienen previstos para atender a los titulares.</li>
        <li class="order">
          Tipo de datos personales contenido en cada base de datos, a los que se realiza tratamiento, como: datos de identificación, ubicación,
          socioeconómicos, sensibles u otros.
        </li>
        <li class="order">Ubicación física de las bases de datos.</li>
        <li class="order">
          Al respecto se preguntará si la base de datos se encuentra almacenada en medios propios, por ejemplo archivadores o servidores (dependiendo
          de si se trata de un archivo físico o una base de datos electrónica), internos o externos a las instalaciones físicas del responsable.
        </li>
        <li class="order">
          Cuando el tratamiento de los datos personales se realice a través de un(os) encargado(s) del tratamiento, se solicitarán los datos de
          identificación y ubicación de ese (esos) encargado(s).
        </li>
        <li class="order">
          Medidas de seguridad y/o controles implementados en la base de datos para minimizar los riesgos de uso no adecuado de los datos personales
          tratados.
        </li>
        <li class="order">Información sobre si se cuenta con la autorización de los titulares de los datos contenidos en las bases de datos.</li>
        <li class="order">Forma de obtención de los datos (directamente del titular o mediante terceros).</li>
      </dl>
      <h4>REDES SOCIALES:</h4>
      <p>
        Las redes sociales como Facebook, Instagram y Twitter, constituyen plataformas complementarias de divulgación de la información
        (comunicación), las cuales son de gran interconexión de los medios digitales de los usuarios y no se encuentran bajo la responsabilidad de
        {{ $locale_local["words"]["title"] }} por ser ajenas a la empresa, por ende, toda la información que los usuarios proporcionan en las redes
        sociales en las que participa {{ $locale_local["words"]["title"] }}, como usuario no constituye ni forma parte de los Datos Personales sujetos
        a la protección de esta Política, siendo de total responsabilidad de la empresa prestadora de esa plataforma.
      </p>
      <h4>TRATAMIENTO DE DATOS COMERCIALES:</h4>
      <p>
        {{ $locale_local["words"]["title"] }} tramitará los datos comerciales e información que considere necesaria para el cumplimiento de su objeto
        social y para toda celebración de contratos con terceros. Los datos de los mismos, serán tratados con la intimidad, derechos a la privacidad,
        el buen nombre de las personas, dentro del proceso del tratamiento de datos personales, y durante todas las actividades que tendrán los
        principios de confidencialidad, seguridad, legalidad, acceso, libertad y transparencia.
      </p>
      <p>Para tal efecto, se reglamenta la firma del Acuerdo de Confidencialidad para la entrega de Datos con todos los proveedores.</p>
      <h4>TRATAMIENTO DE DATOS DE EMPLEADOS DIRECTOS DE LA EMPRESA:</h4>
      <p>
        Todos los datos suministrados por los empleados de {{ $locale_local["words"]["title"] }} serán almacenados, compilados, utilizados,
        compartidos, consultados, transmitidos, intercambiados y transferidos, para dar cumplimiento a las obligaciones derivadas de la relación
        laboral y al ejercicio de los derechos como empleador.
      </p>
      <p>
        Toda la información relativa a los empleados o ex empleados de {{ $locale_local["words"]["title"] }}, serán conservados con el fin de que la
        Compañía, pueda cumplir sus obligaciones como empleador y ejercer los derechos que en esa misma condición le corresponden, de acuerdo con la
        legislación laboral colombiana.
      </p>
      <p>
        Para dar por finalizado el proceso de vinculación de un nuevo empleado en {{ $locale_local["words"]["title"] }}, es necesario garantizar del
        empleado la firma y aceptación de la presente política.
      </p>
      <h4>VIDEOVIGILANCIA:</h4>
      <p>
        {{ $locale_local["words"]["title"] }} informa a sus empleados y visitantes sobre la existencia de mecanismos de seguridad, por medio de la
        notificación en anuncios de la videovigilancia en sitios visibles.
      </p>
      <p>
        {{ $locale_local["words"]["title"] }} cuenta con un sistema de videovigilancia por medio de cámaras fijas, instaladas en sitios estratégicos
        en el interior de sus oficinas e instalaciones, lo cual está establecido en los derechos del tratamiento de datos para los empleados y
        personas naturales manifestando que la información recolectada solo se utilizará con fines de seguridad de los empleados, personas naturales,
        bienes y activos que en ella se contengan. Dicha información podrá ser utilizada como prueba en cualquier momento que sea requerida, ante
        cualquier autoridad, organización oficial y privada que lo solicite. Los archivos obtenidos de la videovigilancia se almacenan en un sistema
        que cuenta con condiciones de seguridad con todo el rigor necesario para tal fin y solo tiene acceso el personal del área administrativa, en
        cuya vinculación se firmó el acuerdo de confidencialidad.
      </p>
      <h4>VIGENCIA DE LA POLÍTICA:</h4>
      <p>
        La presente política rige a partir de la fecha de su publicación y deja sin efectos las demás disposiciones institucionales que le sean
        contrarias. Toda información no contemplada en la presente política, se reglamentará de acuerdo al Régimen General de Protección de Datos
        Personales vigente en Colombia. La actualización de la Políticas de Protección de Datos Personales dependerá de las instrucciones y
        lineamientos de la Dirección Ejecutiva de la {{ $locale_local["words"]["title"] }}, así como de las extensiones reglamentarias del ente de
        vigilancia y control, la Superintendencia de Industria y Comercio. Cualquier inquietud adicional puede escribir al Whatsapp 3187081351, los
        mensajes serán atendidos a la mayor brevedad posible.
      </p>
    </div>
    <div v-if="mylang === 'en'" class="parrafo">
      <h1 class="title">PERSONAL DATA PROCESSING POLICY</h1>
      <p>
        {{ $locale_local["words"]["title"] }}, accepting and complying with the provisions of Law 1581 of 2012 and Regulatory Decree 1377 of 2013 and
        the provisions of article 15 of our Political Constitution, adopts and applies this Policy for the processing of personal data guaranteeing
        privacy, privacy rights, and the good name of people, during the process of processing personal data, in all activities, which will have the
        principles of confidentiality, security, legality, access, freedom and transparency.
      </p>
      <p>
        {{ $locale_local["words"]["title"] }} undertakes not to reveal the information that is typed, attached or transferred to our company, in
        accordance with the regulations of Law 527 that regulates Electronic Commerce in Colombia and Law 1581 of 2012 on the use of confidential
        data. With this Policy for the Treatment and Protection of Personal Data, it is supplemented by leaving agreements and policies issued
        previously without effect.
      </p>
      <p>
        To comply with the data protection policies and the obligations of Law 1581 of 2012, its Regulatory Decrees and the other regulations that
        complement, add or modify it, take into account the following for the handling of information and personal data:
      </p>
      <p>
        Personal information is one of the most important assets, therefore, the treatment of this information is carried out with great care and in
        accordance with what is established by law, guaranteeing people the full exercise and respect for their right of Habeas Data.
      </p>
      <p>
        The information found in its own Database has been obtained in the development of the activity of {{ $locale_local["words"]["title"] }}, its
        compilation has been made and will always be done in accordance with the legal criteria and regulations, however, said company name acts under
        the brand called Models1A, therefore, from now on when referring to the person in charge, the company name will be named, understanding that
        it refers to the latter.
      </p>
      <h4>SCOPE OF THE DATA PROTECTION POLICY:</h4>
      <p>
        The Personal Data Protection Policy of {{ $locale_local["words"]["title"] }} will apply to all Databases and/or files that contain Personal
        Data, which for {{ $locale_local["words"]["title"] }} is subject to Treatment as responsible and / or in charge of the processing of Personal
        Data.
      </p>
      <p>
        The Processing of Personal Data must be done in the terms, conditions and scope of the authorization of the Holder and/or in application of
        the special rules when any legal exception to do so is appropriate. Any type of request, product of the exercise of the duties and rights
        enshrined in the policy, may be addressed to Calle 32F # 78 - 78 in the city of Medellín, Colombia, with contact telephone number 3187081351.
      </p>
      <h4>RESPONSIBLE FOR THE PROCESSING OF PERSONAL DATA</h4>
      <p>{{ $locale_local["words"]["title"] }}, will be responsible for the processing of personal data and databases.</p>
      <h4>PURPOSE OF THE COLLECTION AND PROCESSING OF PERSONAL DATA</h4>
      <p>The purpose of the Personal Data Protection Policy seeks:</p>
      <dl>
        <li class="order">Implement the procedures for the collection and processing of personal data according to the provisions of the law.</li>
        <li class="order">Generate an organized scheme to safeguard the private, semi-private, public and sensitive data of its owners.</li>
      </dl>
      <p>The purpose of {{ $locale_local["words"]["title"] }} regarding the collection and processing of Personal Data:</p>

      <p>
        <em>APPLICANTS, ALLIES OR USERS:</em>
        It seeks to bring its Users or applicants information and benefits of belonging to {{ $locale_local["words"]["title"] }}, therefore, the
        Applicant accepts that {{ $locale_local["words"]["title"] }} contact you through different channels such as landline phone, cell phone,
        messages to your cell phone, email and social networks, to offer you information and benefits.
      </p>
      <p>
        <em>EMPLOYEES:</em>
        This purpose specifically includes the payment of salaries and obligations as an employer of affiliations and contributions to social security
        and compensation funds, both for employees and their families and control of labor news such as permits, disabilities, access control and
        hours. employee's job.
      </p>
      <p>
        <em>SUPPLIERS:</em>
        Permanent contact for requesting quotes and management of commercial relationships that arise, in order to acquire their products or services
        as inputs for the operation according to the company name of {{ $locale_local["words"]["title"] }}
      </p>
      <p>
        <em>VISITORS: {{ $locale_local["words"]["title"] }}</em>
        receives infrequently and not often visitors at its facilities, taking into account that all the management of its activities is carried out
        through electronic commerce. The information provided by visitors by way of access control is registered by the administrative staff seeking
        to obtain control against possible security incidents and identification of the people who enter the facilities of
        {{ $locale_local["words"]["title"] }}
      </p>
      <h4>DEFINITIONS:</h4>
      <dl>
        <li class="order">Authorization: Prior, express and informed consent of the Owner to carry out the Processing of personal data.</li>
        <li class="order">
          Privacy Notice: Verbal or written communication generated by the Responsible addressed to the Owner for the processing of their personal
          data, through which they are informed about the existence of the Information Processing policies that will be applicable to them, the way to
          access the same and the purposes of the Treatment that is intended to be given to personal data.
        </li>
        <li class="order">Database: Organized set of personal data that is subject to Treatment.</li>
        <li class="order">
          Personal data: Any information linked or that can be associated with one or several determined or determinable natural persons.
        </li>
        <li class="order">Private data: It is the data that, due to its intimate or reserved nature, is only relevant to the owner.</li>
        <li class="order">
          Sensitive data: Sensitive data is understood to be those that affect the Owner's privacy or whose improper use may generate discrimination,
          such as those that reveal racial or ethnic origin, political orientation, religious or philosophical convictions, union membership, social
          organizations, human rights or that promote the interests of any political party or that guarantee the rights and guarantees of opposition
          political parties, as well as data related to health, sexual life and biometric data.
        </li>
        <li class="order">
          Treatment Manager: Natural or legal person, public or private, that by itself or in association with others, performs the Processing of
          personal data on behalf of the Treatment Manager.
        </li>
        <li class="order">
          Responsible for the Treatment: Natural or legal person, public or private, that by itself or in association with others, decides on the
          database and/or the Treatment of the data.
        </li>
        <li class="order">Owner: Natural person whose personal data is subject to Treatment.</li>
        <li class="order">
          Treatment: Any operation or set of operations on personal data, such as the collection, storage, use, circulation or deletion of the same.
        </li>
        <li class="order">
          Terms and Conditions: general framework in which the conditions are established for the participants of promotional or related activities.
        </li>
      </dl>
      <h4>AREA OF APPLICATION:</h4>
      <p>
        The Personal Data processing policies must be known and applied by all officials, employees, dependencies, clients and suppliers of
        {{ $locale_local["words"]["title"] }}
      </p>
      <h4>{{ $locale_local["words"]["title"] }} DATABASE:</h4>
      <p>
        The policies and procedures apply to the Database managed by {{ $locale_local["words"]["title"] }} and will be registered in accordance with
        the provisions of the law.
      </p>
      <h4>POLICY CONSULTATION:</h4>
      <p>
        The Personal Data Protection Policy must be announced, presented and arranged on the official site of
        {{ $locale_local["words"]["title"] }}
        “models1a.com”, presenting easy consultation and access by the general public.
      </p>
      <h4>RIGHTS OF THE HOLDERS OF PERSONAL DATA:</h4>
      <p>
        The holders of the personal data are the natural persons whose personal data is processed by
        {{ $locale_local["words"]["title"] }}
      </p>
      <p>
        In accordance with the provisions of the applicable current regulations on data protection, the following are the rights of the owners of
        personal data, which they can exercise at any time:
      </p>
      <dl>
        <li class="order">
          Access, know, update and rectify the personal data on which {{ $locale_local["words"]["title"] }} is performing the treatment. In the same
          way, the owner can request at any time that his data be updated or rectified when he finds that his data is partial, incorrect, inaccurate,
          incomplete, divided, misleading, or whose treatment has not been authorized or is expressly prohibited.
        </li>
        <li class="order">To be informed by {{ $locale_local["words"]["title"] }} regarding the use that has been given to your personal data.</li>
        <li class="order">
          Revoke the authorization and/or request the deletion of the data when the principles, rights, and constitutional and legal guarantees are
          not respected in the treatment.
        </li>
        <li class="order">
          Request proof of authorization granted to {{ $locale_local["words"]["title"] }} for data processing, by any valid means, except in cases
          where authorization is not required.
        </li>
        <li class="order">
          Submit to the Superintendence of Industry and Commerce, complaints for violations of the provisions of Law 1581 of 2012 and other
          regulations that modify, add or complement it, after consulting or requesting {{ $locale_local["words"]["title"] }}
        </li>
        <li class="order">Access and consult your personal data subject to treatment free of charge..</li>
      </dl>
      <h4>UPDATE, CORRECT, RECTIFY OR DELETE THE HOLDER'S DATA:</h4>
      <p>
        The owner of the personal data can request {{ $locale_local["words"]["title"] }} Through the email habeasdata@omgwgroup.com for requests, that
        your personal data be updated, corrected, rectified or deleted, if you wish or if you consider that there is a breach of any of the duties in
        the General Data Protection Regime Personal or in this Policy. In order to carry out the update, correction, rectification or deletion, the
        Holder must process the request addressed to the person in charge or in charge of the treatment indicating:
      </p>
      <dl>
        <li class="order">Full name and identification of the data owner.</li>
        <li class="order">Detailed description of the facts that give rise to the request.</li>
        <li class="order">Owner's location data such as address, department, city and contact telephone number.</li>
        <li class="order">Description of the procedure you wish to carry out (update, correction, rectification or deletion).</li>
        <li class="order">And if you consider it necessary to attach documents that support the request (this point is optional).</li>
      </dl>
      <p>
        Received the request from the Owner of the personal data through the request mail habeasdata@omgwgroup.com, with the points correctly
        completed to process it in a way to the person in charge of the treatment, keeping the case open in a term not exceeding five (5 ) business
        days from the date of receipt, time in which a response and solution to the request must be given. Likewise, there will be two (2) business
        days to notify the person in charge of providing a solution (competent); If the person who receives it is not entitled to answer it, they will
        have fifteen (15) business days from the day the request is received to respond to it. If the Owner does not comply with the points correctly
        completed to process the request addressed to the person in charge or in charge of the treatment, the Owner (interested party) will be asked
        to correct the requirements within the following five (5) days from the date of receipt. After fifteen (15) counted from the date, since the
        Holder is requested to comply with the established requirements, and not obtaining any response or insisting on the wrong procedure, it will
        be assimilated as withdrawal of the request. If on the part of
        {{ $locale_local["words"]["title"] }}, it has not been possible to respond to the request within the indicated term, the Holder (interested
        party) will be informed, listing and detailing the reasons why it was not possible to meet his request and notifying him of the date in which
        it will be resolved..
      </p>
      <h4>REVOKE THE AUTHORIZATION TO PROCESS PERSONAL DATA:</h4>
      <p>
        At any time, the owner of the Personal Data may revoke the authorization for the processing of their Personal Data provided to
        {{ $locale_local["words"]["title"] }}; For this, you must manage the request addressed to the person in charge of the treatment or to the
        person in charge of it, detailing the purpose of your request. The Owner of the Personal Data in order to manage the revocation before
        {{ $locale_local["words"]["title"] }}, must carry out the same steps and requirements established in the procedure for updating, correction,
        rectification or deletion of personal data.
      </p>
      <h4>QUERIES ABOUT THE PROCESSING OF DATA BY ITS HOLDERS:</h4>
      <p>
        The Holders may consult their personal information held by {{ $locale_local["words"]["title"] }}, who in turn will be willing to provide all
        the information that is linked to the identification of the Holder.
      </p>
      <p>Regarding the attention to requests for consultation of Personal Data, {{ $locale_local["words"]["title"] }} guarantees:</p>
      <dl>
        <li>Enable electronic means of communication or others that it considers pertinent.</li>
        <li>Offer and notify the forms, systems and other methods of contact.</li>
        <li>
          Deal with the cases of attention of the requests in a maximum term of fifteen (15) business days, which will be counted from the date of
          request. If it is not possible to attend to it within the defined time, the interested party will be informed before its expiration,
          notifying the reasons for the delay and the new date on which a solution will be given. This new term may not exceed five (5) additional
          business days to the initial term.
        </li>
      </dl>
      <h4>CANDIDATES:</h4>
      <p>
        {{ $locale_local["words"]["title"] }} It is not a company dedicated to searching, locating, channeling and contacting possible candidates for
        a vacancy, however, it has electronic channels so that people who wish to be part of the work team, join as allies or contractors can attach
        their information.
      </p>
      <p>
        {{ $locale_local["words"]["title"] }} makes available to the candidate or interested party, at any time, the possibility of revoking the
        authorization for the processing of their personal data supplied on a completely voluntary basis, managing the request addressed to the person
        responsible for the treatment or the person in charge of the treatment and detailing the purpose of their request .
      </p>
      <p>
        If a candidate or interested party sends their information directly to {{ $locale_local["words"]["title"] }} by some means of contact, and it
        has not been added as a pre-selection in any process, it is immediately removed from all the records of {{ $locale_local["words"]["title"] }},
        guaranteeing the protection of your personal data and preventing them from being used for activities or selection processes in which the
        candidate does not express interest in participating.
      </p>
      <p>
        The self-applicant of Personal Data must manage the revocation before {{ $locale_local["words"]["title"] }}, carrying out the same steps and
        requirements established in the procedure for updating, correcting, rectifying or deleting personal data.
      </p>
      <h4>DATA CATEGORY:</h4>
      <p>
        {{ $locale_local["words"]["title"] }} In development of the principle of private autonomy, and in accordance with the data processed, and in
        accordance with current legislation, it has developed the following data classification:
      </p>
      <dl>
        <li class="order">Personal Data: Set of information that can be related to one or more natural persons.</li>
        <li class="order">
          Public data: Public data is all that is contained in public documents, related to the marital status of people, their profession or trade
          and their quality as a merchant or public servant. They are public data, for example, the contents in the citizenship card, in public
          records, in judicial sentences duly executed and not subject to reservation. Therefore, it will also be public data that is not
          semi-private, private or sensitive.
        </li>
        <li class="order">
          Semi-private data: It is that whose knowledge or disclosure is of interest to its owner and a certain group of people or social sector. For
          example, commercial or professional activity.
        </li>
        <li class="order">
          Private data: It is that whose knowledge or disclosure, due to its intimate and reserved nature, is of interest only to its owner.
        </li>
        <li class="order">Reserved data: It is one that has a confidential nature or a high commercial value by itself.</li>
        <li class="order">
          Sensitive data: It is one that affects the privacy of its owner or that its improper use can generate discrimination. For example, those
          related to sexual orientation, political orientation, ethnic or racial origin, religious or philosophical convictions, participation in
          trade unions, Human Rights or social groups, among others.
        </li>
      </dl>
      <h4>PROCESSING OF PERSONAL DATA OF A SENSITIVE NATURE:</h4>
      <p>
        In accordance with the Personal Data Protection Law, data of a sensitive nature is considered to be data that affects privacy or whose
        improper use may generate discrimination or accusations. The Treatment of Personal Data of a sensitive nature is prohibited by law, unless
        there is the express, prior and informed authorization of the Holder, among other exceptions enshrined in Article 6 of Law 1581 of 2012. Data
        of a sensitive nature are those related to:
      </p>
      <dl>
        <li class="order">Racial or ethnic origin.</li>
        <li class="order">Political orientation.</li>
        <li class="order">Religious / philosophical convictions.</li>
        <li class="order">Membership of trade unions, social organizations, human rights organizations or political parties.</li>
        <li class="order">Sex life.</li>
        <li class="order">Biometric data (such as fingerprint, signature and photo).</li>
      </dl>
      <p>
        The provision of one or some of the personal data of a sensitive nature to aspire to a relationship as a Business Partner is a completely
        autonomous act on the part of the candidate, in case of not being selected, the data will be deleted immediately of all the records of
        {{ $locale_local["words"]["title"] }}, guaranteeing their protection and preventing them from being used for activities or selection processes
        in which the candidate does not express interest in participating.
      </p>
      <h4>REGULATORY AND LEGAL FRAMEWORK:</h4>
      <p>
        The personal data protection policies of {{ $locale_local["words"]["title"] }} are governed by the following rules internally and externally:
      </p>
      <p>
        LAW 527 OF 1999: Defines and regulates the access and use of data messages, electronic commerce and digital signatures, and certifying
        entities are established and other provisions are issued. Likewise, it introduces the concept of functional equivalent, electronic signature
        as mechanisms of authenticity, availability and confidentiality of information.
      </p>
      <p>
        LAW 1266 OF 2008: By which the general provisions of Habeas Data are dictated and the management of the information contained in personal
        databases is regulated, especially financial, credit, commercial, service and information from third countries and is dictated by other
        provisions.
      </p>
      <p>
        LAW 1273 OF 2009: Law by means of which the legal right of information and personal data is created and protected. Likewise, criminal conduct
        is classified as computer damage, violation of personal data, abusive access to computer systems, interception of computer data, theft by
        computer means, among others.
      </p>
      <p>LAW 1581 OF 2012: By which general provisions are issued for the protection of personal data.</p>
      <p>
        DECREE 1377 OF 2013: With which Law 1581 of 2012 is regulated, on aspects related to the authorization of the Holder of information for the
        Treatment of their personal data, the Treatment policies of the Responsibles and Managers, the exercise of the rights of the Holders of
        information, the transfers of personal data and the responsibility demonstrated against the Processing of personal data.
      </p>
      <p>DECREE 368 OF 2014: By which the operations through financing systems provided for in article 45 of Law 1480 of 2011 are regulated.</p>
      <p>
        DECREE 886 OF 2014: By which article 25 of Law 1581 of 2012 is regulated, regarding the National Registry of Personal Data Bases, which is in
        charge of the Superintendence of Industry and Commerce, and where those who act as Responsible of the processing of personal data, they must
        register their Database following the instructions of this decree.
      </p>
      <h4>FUNCIONES DEL RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES:</h4>
      <p>
        Among the tasks of the person responsible for the processing of personal data, there is the designation of a person within the company who
        fulfills the following functions:
      </p>
      <dl>
        <li class="order">
          Guarantee the preparation, implementation and promotion of a system that allows managing the risks of personal data processing.
        </li>
        <li class="order">Communicate and promote a culture of data protection within the organization.</li>
        <li class="order">
          Integrate and link all areas of {{ $locale_local["words"]["title"] }} to ensure a transversal implementation of the Personal Data Protection
          Policies.
        </li>
        <li class="order">
          Verify and audit that the {{ $locale_local["words"]["title"] }} are registered in the National Registry of Databases, and update the report
          according to the instructions of the Superintendence of Industry and Commerce.
        </li>
        <li class="order">Schedule and guarantee constant training of the company in the protection of personal data.</li>
        <li class="order">
          Analyze and diagnose the responsibilities of the positions within {{ $locale_local["words"]["title"] }}, to lead the training program in the
          protection of personal data.
        </li>
        <li class="order">
          Guarantee that within the process of analyzing employee performance, training and use on the protection of personal data, is at a high
          level.
        </li>
        <li class="order">
          Carry out the training and transmit the responsibility to the new employees who, due to the conditions of their work, have access to the
          Databases.
        </li>
        <li class="order">
          Organize and monitor the implementation of internal audit plans, to verify compliance with personal data processing policies..
        </li>
        <li class="order">Obtain, when required, the declarations of conformity from the Superintendence of Industry and Commerce.</li>
      </dl>
      <h4>CONFIDENTIALITY AND SECURITY OF THE DATABASE:</h4>
      <p>
        {{ $locale_local["words"]["title"] }} will apply the best practices for the security, discretion, protection, storage and confidentiality of
        the Personal Data of the holders. It will verify when appropriate, the origin of the legal exceptions to deliver the personal data to the
        authorities and in the pertinent cases.
      </p>
      <h4>GUARANTEES OF ACCESS TO THE HOLDER OF PERSONAL DATA:</h4>
      <p>
        {{ $locale_local["words"]["title"] }} guarantees the right of access to the owner of the data, with prior accreditation of their identity,
        legitimacy and at no cost, to their personal data through different means, mainly electronic, that allow the owner direct access to them. Said
        access must be offered without any limit and the owner must be allowed the possibility of knowing and updating them online.
      </p>
      <h4>DATABASE UPDATE:</h4>
      <p>Update the information as the data is obtained, in accordance with the provisions of Law 1581 of 2012.</p>
      <h4>PROOF OF THE HOLDER&#39;S AUTHORIZATION:</h4>
      <p>
        Keep the proof of the authorization granted by the owners of the personal data for its treatment, using digital mechanisms and security rules
        necessary to keep the record of the form and date. {{ $locale_local["words"]["title"] }} establishes electronic repositories to safeguard the
        information.
      </p>
      <h4>REGISTRATION IN THE DATABASE REGISTRY OF THE SUPERINTENDENCY OF INDUSTRY AND COMMERCE:</h4>
      <p>
        {{ $locale_local["words"]["title"] }} will comply with the obligations imposed by the regulations in relation to the registration and reports
        that must be delivered to the competent authorities. For purposes of registering the Database, a procedure will be carried out taking into
        account the following parameters:
      </p>
      <dl>
        <li class="order">Number of databases with personal information.</li>
        <li class="order">Number of holders for each database.</li>
        <li class="order">Detailed information on the channels or means that are planned to serve the owners.</li>
        <li class="order">
          Type of personal data contained in each database, to which treatment is carried out, such as: identification data, location, socioeconomic,
          sensitive or others..
        </li>
        <li class="order">Physical location of the databases.</li>
        <li class="order">
          In this regard, it will be asked if the database is stored in its own media, for example filing cabinets or servers (depending on whether it
          is a physical file or an electronic database), internal or external to the physical facilities of the person in charge.
        </li>
        <li class="order">
          When the processing of personal data is carried out through a processor(s), the identification and location data of said processor(s) will
          be requested.
        </li>
        <li class="order">
          Security measures and/or controls implemented in the database to minimize the risks of improper use of the personal data processed.
        </li>
        <li class="order">Information on whether you have the authorization of the owners of the data contained in the databases.</li>
        <li class="order">Form of obtaining the data (directly from the owner or through third parties).</li>
      </dl>
      <h4>SOCIAL MEDIA:</h4>
      <p>
        Social networks such as Facebook, Instagram and Twitter, constitute complementary platforms for the dissemination of information
        (communication), which are highly interconnected with the digital media of the users and are not under the responsibility of
        {{ $locale_local["words"]["title"] }} Because they are outside the company, therefore, all the information that users provide on the social
        networks in which {{ $locale_local["words"]["title"] }}, participates, as a user does not constitute or form part of the Personal Data subject
        to the protection of this Policy, being full responsibility of the company providing that platform.
      </p>
      <h4>PROCESSING OF COMMERCIAL DATA:</h4>
      <p>
        {{ $locale_local["words"]["title"] }} will process the commercial data and information that it deems necessary for the fulfillment of its
        corporate purpose and for any conclusion of contracts with third parties. Their data will be treated with privacy, rights to privacy, the good
        name of the people, within the process of processing personal data, and during all activities that will have the principles of
        confidentiality, security, legality, access , freedom and transparency.
      </p>
      <p>For this purpose, the signing of the Confidentiality Agreement for the delivery of Data with all providers is regulated.</p>
      <h4>TRATAMIENTO DE DATOS DE EMPLEADOS DIRECTOS DE LA EMPRESA:</h4>
      <p>
        All the data provided by the employees of {{ $locale_local["words"]["title"] }} will be stored, compiled, used, shared, consulted,
        transmitted, exchanged and transferred, to comply with the obligations derived from the employment relationship and the exercise of rights as
        an employer.
      </p>
      <p>
        All information related to the employees or former employees of {{ $locale_local["words"]["title"] }}, will be kept so that the Company can
        fulfill its obligations as an employer and exercise the rights that correspond to it in that same condition, in accordance with labor
        legislation. Colombian.
      </p>
      <p>
        At the time of admission to {{ $locale_local["words"]["title"] }}, For new employees with a labor contract, it is a requirement that at the
        time they start their assigned tasks, they state that they know, accept and apply the Personal Data Protection Policies.
      </p>
      <p>
        To end the process of hiring a new employee at {{ $locale_local["words"]["title"] }}, it is necessary to guarantee the employee&#39;s
        signature and acceptance of this policy.
      </p>
      <h4>VIDEO SURVEILLANCE:</h4>
      <p>
        {{ $locale_local["words"]["title"] }} informs its employees and visitors about the existence of security mechanisms, by means of video
        surveillance announcements on visible sites.
      </p>
      <p>
        {{ $locale_local["words"]["title"] }} has a video surveillance system through fixed cameras, installed in strategic locations inside its
        offices and facilities, which is established in the rights of data processing for employees and natural persons stating that the information
        collected will only be used for the safety of employees, natural persons, goods and assets contained in it. This information may be used as
        evidence at any time that it is required, before any authority, official and private organization that requests it. The files obtained from
        video surveillance are stored in a system that has security conditions with all the rigor necessary for this purpose and only the personnel of
        the administrative area, in whose connection the confidentiality agreement was signed, has access..
      </p>
      <h4>VALIDITY OF THE POLICY:</h4>
      <p>
        This policy is in force from the date of its publication and renders ineffective the other institutional provisions that are contrary to it.
        All information not contemplated in this policy will be regulated in accordance with the General Regime for the Protection of Personal Data in
        force in Colombia. Updating the Personal Data Protection Policies will depend on the instructions and guidelines of the Executive Directorate
        of {{ $locale_local["words"]["title"] }}, as well as the regulatory extensions of the surveillance and control entity, the Superintendence of
        Industry and Commerce. Any additional concerns you can write to Whatsapp 3187081351, the messages will be answered as soon as possible..
      </p>
    </div>
  </div>
</template>
<script>
import Politic from "./Politics";
export default {
  data() {
    return {
      mylang: localStorage.getItem("user-language").substring(0, 2) || navigator.language.substring(0, 2),
    };
  },
};
</script>
<style lang="scss">
.parrafo {
  padding: 5px;
  text-align: left;
  height: 120px;
  overflow-y: scroll;
  overflow: scroll;
  overflow: auto;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 6px;
  p {
    margin-top: 7px;
    margin-bottom: 10px;
  }
  .order {
    margin: 10px 0px 10px 10px;
  }
  .title {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 7px;
  }
  &::-webkit-scrollbar{
    display: none;
  }
}
</style>
